import React, { useContext } from 'react'
import { Button, Grid } from '@material-ui/core'
import UserNav from './UserNav'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { AuthContext } from '../../Providers/AuthProvider'
import { DialogContext } from '../../Providers/DialogProvider'
import { useTranslation } from 'react-i18next'
import { TALK_TO_EXPERTS_LINK } from './Links'
import { useLocation } from 'react-router'

const SessionNav = () => {
  const { user, loaded } = useContext(AuthContext)
  const location = useLocation()
  const { showDialog } = useContext(DialogContext)
  const { t } = useTranslation()
  const handleDialog = (dialog) => () => showDialog(dialog)
  if (!loaded) {
    return <></>
  }

  return (
    <div className='toolbar-session'>
      {user ? (
        <UserNav user={user} />
      ) : (
        <Grid container direction='row' spacing={2} style={{ minWidth: '17em' }}>
          {window.inclusivv && (
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {/* <Button href={TALK_TO_EXPERTS_LINK} variant='contained' color='primary' className='toolbar-nav-link coco-session-btn no-wrap'>
                {"Let's Chat"}
              </Button> */}
              {/* <Button disableElevation href='https://help.inclusivv.co/en/' color='primary' className='toolbar-nav-link coco-session-btn no-wrap' target='blank'>
                Help Center
              </Button> */}
            </Grid>
          )}
          <Grid item xs={5}>
            {!['/login', '/join'].includes(location.pathname.toLowerCase()) && (
              <Button onClick={handleDialog('login')} className='toolbar-nav-link reg-right coco-green-text' variant='outlined'>
                {t('auth.login')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default flow(observer)(SessionNav)
